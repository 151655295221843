import { ApiService } from "./ApiService"

export async function apiExportXlsx({ master_name, filter }) {
  return ApiService.fetchData({
    url: `import_data/export_data`,
    method: "get",
    params: { master_name, ...filter },
    responseType: "blob",
  })
}

export async function apiGetClockTime() {
  return ApiService.fetchData({
    url: `attendance/clock_in_data`,
    method: "get",
  })
}

export async function apiClockInOut() {
  return new Promise((resolve, reject) => {
    const requestLocation = () => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await clockInOutApi(latitude, longitude);
            resolve(response);
          } catch (error) {
            reject(error);
          }
        },
        async (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // Show alert if permission is denied
            alert('Turn on Location Services to Allow "Party Cruisers Limited" to Determine Your Location by Clicking the Location icon in the Address bar, and then Always allow.');
            // Re-request location after alert
          } else {
            reject(error);
          }
        },
        { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
      );
    };

    // Initial call to request location
    requestLocation();
  });
}


async function clockInOutApi(latitude, longitude) {
  try {
    const response = await ApiService.fetchData({
      url: `attendance/log_activity`,
      method: "post",
      ...(latitude && { data: { location: { lat: latitude, lng: longitude } } })
    });
    console.log(response);
    return response; // Return response instead of resolving it here
  } catch (error) {
    console.log(error);
    throw error; // Throw error to be caught in apiClockInOut
  }
}
// export async function apiClockInOut() {
//   return ApiService.fetchData({
//     url: "attendance/log_activity",
//     method: "post",
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded",
//     },
//   })
// }
