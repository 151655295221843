import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { extractTime } from "constants/CommonFunction"
import {
  apiClockInOut,
  apiExportXlsx,
  apiGetClockTime,
} from "services/MasterService"
import { apiGetOtherDocument } from "services/OtherDocumentService"

export const SLICE_NAME = "otherDocument"

const initialState = {
  xlsxLoader: false,
  clockLoader: false,
  clockTimeData: {
    clockInTime: "",
    clockInStatus: "",
  },
}

export const exportXlsxFile = createAsyncThunk(
  `${SLICE_NAME}/exportXlsxFile`,
  async ({ master_name, filter }) => {
    try {
      const response = await apiExportXlsx({ master_name, filter })
      if (response && response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        )
        return {
          status: "success",
          data: response.data,
        }
      }
    } catch (errors) {
      return {
        statusText: "failed",
        message: errors?.response,
      }
    }
  }
)

export const getClockTime = createAsyncThunk(
  `${SLICE_NAME}/getClockTime`,
  async () => {
    try {
      const response = await apiGetClockTime()
      return response?.data
    } catch (error) {
      throw error
    }
  }
)

export const clockInOut = createAsyncThunk(
  `${SLICE_NAME}/clockInOut`,
  async () => {
    try {
      const response = await apiClockInOut()
      return response
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || "Geo Location Not Found",
      }
    }
  }
)

const otherDocument = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.xlsxLoader = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(exportXlsxFile.pending, state => {
      state.xlsxLoader = true
    })
    builder.addCase(exportXlsxFile.fulfilled, (state, action) => {
      state.xlsxLoader = false
    })
    builder.addCase(exportXlsxFile.rejected, state => {
      state.xlsxLoader = false
    })
    // clock in time
    builder.addCase(getClockTime.pending, state => {
      state.clockLoader = true
    })
    builder.addCase(getClockTime.fulfilled, (state, action) => {
      state.clockLoader = false
      state.clockTimeData.clockInTime = extractTime(
        action.payload.last_clock_in_time
      )
      state.clockTimeData.clockInStatus = action.payload.last_clock_in_status
    })
    builder.addCase(getClockTime.rejected, state => {
      state.clockLoader = false
    })
  },
})

export const { setLoading, setTableRowData, setDocumentModal, setCurrentPage } =
  otherDocument.actions

export default otherDocument.reducer
