import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import PropTypes from "prop-types"
import {
  editEmergencyDetail,
  fetchProfileDetail,
  setEditEmergencyDetail,
} from "store/onBoarding/onBordingSlice"
import { useDispatch, useSelector } from "react-redux"
import { relation } from "constants/Constants"
import { getUserId } from "constants/CommonFunction"

const OnboardingEmergencyContactForm = () => {
  const dispatch = useDispatch()
  const { profileDetails, userId } = useSelector(state => state.onBoarding)
  return (
    <>
      <div className="d-flex justify-content-center">
        <Row className="profile-container">
          <Col xl="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={{
                    emg_name_1: profileDetails?.emg_name_1,
                    emg_relation_1: profileDetails?.emg_relation_1,
                    emg_mobile_1: profileDetails?.emg_mobile_1,
                    emg_address_1: profileDetails?.emg_address_1,
                    emg_name_2: profileDetails?.emg_name_2,
                    emg_relation_2: profileDetails?.emg_relation_2,
                    emg_mobile_2: profileDetails?.emg_mobile_2,
                    emg_address_2: profileDetails?.emg_address_2,
                  }}
                  validationSchema={Yup.object({
                    emg_name_1: Yup.string()
                      .min(3, "Must be at least 3 characters")
                      .max(30, "Must be at most 30 characters")
                      .required("Required"),
                    emg_relation_1: Yup.string().required("Required"),
                    emg_mobile_1: Yup.string().required()
                      .matches(/^\d+$/, "invalid number")
                      .min(6, "Min 6 digit")
                      .max(16, "only 16 digit allow")
                      .required("Required"),
                    emg_address_1: Yup.string().required("Required"),
                    emg_name_2: Yup.string()
                      .min(3, "Must be at least 3 characters")
                      .max(30, "Must be at most 30 characters")
                      .required("Required"),
                    emg_relation_2: Yup.string().required("Required"),
                    emg_mobile_2: Yup.string()
                      .matches(/^\d+$/, "invalid number")
                      .min(6, "Min 6 digit")
                      .max(16, "only 16 digit allow")
                      .required("Required"),
                    emg_address_2: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const payload = {
                      ...values,
                      id: userId,
                    }
                    const response = await dispatch(
                      editEmergencyDetail(payload)
                    )
                    if (response.payload?.status === "success") {
                      toastr.success(response?.payload?.data?.data?.message)
                    } else {
                      toastr.error("Something went wrong.")
                    }
                    await dispatch(fetchProfileDetail(userId))
                    await dispatch(setEditEmergencyDetail(false))
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    isSubmitting,
                    errors,
                    touched,
                    dirty,
                  }) => (
                    <Form>
                      <Row>
                        <Col md={12}>
                          <CardTitle className="header-text">
                            Emergency Contact
                          </CardTitle>
                          <hr className="underline" />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="values" htmlFor="emg_name_1">
                            Name<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="emg_name_1"
                            placeholder="Enter Name"
                            type="text"
                            className={`form-control ${
                              touched.emg_name_1 && errors.emg_name_1
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="emg_name_1"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="values" htmlFor="emg_relation_1">
                            Relationship<span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="emg_relation_1"
                            value={relation.find(
                              option => option.value === values.emg_relation_1
                            )}
                            onChange={option =>
                              setFieldValue("emg_relation_1", option.value)
                            }
                            options={relation}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="emg_relation_1"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="values" htmlFor="emg_mobile_1">
                            Mobile<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="emg_mobile_1"
                            placeholder="Enter Mobile"
                            type="number"
                            className={`form-control ${
                              touched.emg_mobile_1 && errors.emg_mobile_1
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="emg_mobile_1"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label className="values" htmlFor="emg_address_1">
                            Address<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="emg_address_1"
                            placeholder="Enter Address"
                            type="text"
                            className={`form-control ${
                              touched.emg_address_1 && errors.emg_address_1
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="emg_address_1"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                      </Row>

                      {/* Emergency Contact 2 */}
                      <Row>
                        <Col md={12}>
                          <CardTitle className="header-text">
                            Emergency Contact 2
                          </CardTitle>
                          <hr className="underline" />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="values" htmlFor="emg_name_2">
                            Name<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="emg_name_2"
                            placeholder="Enter Name"
                            type="text"
                            className={`form-control ${
                              touched.emg_name_2 && errors.emg_name_2
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="emg_name_2"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="values" htmlFor="emg_relation_2">
                            Relationship<span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="emg_relation_2"
                            value={relation.find(
                              option => option.value === values.emg_relation_2
                            )}
                            onChange={option =>
                              setFieldValue("emg_relation_2", option.value)
                            }
                            options={relation}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="emg_relation_2"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="values" htmlFor="emg_mobile_2">
                            Mobile<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="emg_mobile_2"
                            placeholder="Enter Mobile"
                            type="number"
                            className={`form-control ${
                              touched.emg_mobile_2 && errors.emg_mobile_2
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="emg_mobile_2"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label className="values" htmlFor="emg_address_2">
                            Address<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="emg_address_2"
                            placeholder="Enter Address"
                            type="text"
                            className={`form-control ${
                              touched.emg_address_2 && errors.emg_address_2
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="emg_address_2"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <button
                          className="d-flex align-items-center btn btn-md btn-outline-secondary"
                          type="reset"
                          onClick={() => {
                            dispatch(setEditEmergencyDetail(false))
                          }}
                        >
                          <i className="dripicons-cross mx-1 d-flex" />
                          Cancel
                        </button>
                        <button
                          className="btn btn-md btn-primary"
                          type="submit"
                          disabled={isSubmitting || !dirty}
                        >
                          {isSubmitting ? "Updating..." : "Update"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default OnboardingEmergencyContactForm
